// ProjectDisplay.jsx
import React, { useState } from "react";
import { useParams,Link } from "react-router-dom";
import GitHubIcon from "@material-ui/icons/GitHub";
import { ProjectList } from "../helpers/ProjectList";
import "../styles/ProjectDisplay.css";

function ProjectDisplay() {
  const { projectId } = useParams();
  const project = ProjectList.find((p) => p.id === parseInt(projectId, 10));
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  if (!project) {
    return <div className="project">Project not found!</div>;
  }

  const { name, image, description, skills, link, githubLink } = project;

  const toggleDescription = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };

  return (
    <div className="project-details">
      <div className="project-header">
        <h2 className="project-title">{name}</h2>
        <Link to="/projects">
          <button className="back-btn">X</button>
        </Link>
      </div>

      <div className="image-container">
        <a href={link} target="_blank">
          <img src={image} alt={name} />
        </a>
      </div>

      <div className="project-description">
        <h3>Description</h3>
        {isDescriptionExpanded ? (
          <p>{description}</p>
        ) : (
          <p>{description.slice(0, 150)}...</p>
        )}
        <button className="read-more-button" onClick={toggleDescription}>
          {isDescriptionExpanded ? "Read Less" : "Read More"}
        </button>
      </div>

      <div className="project-skills">
        <h3>Skills</h3>
        <p>{skills}</p>
      </div>

      <div className="preview-links">
        <p>
          <a
            className="preview-button"
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Demo
          </a>
        </p>
        <p>
          <a
            className="github-button"
            href={githubLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            GitHub
          </a>
        </p>
      </div>
    </div>
  );
}

export default ProjectDisplay;
