import React from "react"
import {
    VerticalTimeline,
    VerticalTimelineElement
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";
import GetAppIcon from "@material-ui/icons/GetApp";
import DescriptionIcon from "@material-ui/icons/Description";
import "../styles/Experience.css"; 

function Experience() {
  // Function to handle the download of the resume
  const handleDownloadResume = () => {
    // You can replace the placeholder URL with the actual URL of your resume file
    const resumeURL =
      "https://drive.google.com/file/d/1g4Jefxq0E83mL0xBuPbpa8-1p_J6QnPs/view?usp=drive_link";
    window.open(resumeURL, "_blank");
  };

  return (
    <div className="experience">
      <VerticalTimeline lineColor="#3e497a">
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
          icon={<DescriptionIcon />}
        >
          <button
            className="download-resume-button"
            onClick={handleDownloadResume}
          >
            <GetAppIcon className="download-icon" />
            Download Resume
          </button>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="June 2023 - Present"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Systems Admin - NPBC
          </h3>
          <h4 className="vertical-timeline-element-subtitle">Nairobi, Kenya</h4>
          <p>
            Leading a comprehensive transformation of the college's ICT
            infrastructure, in alignment with the strategic plan,resulting in enhanced operational efficiency.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="June 2021 - December 2022"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Software Developer - Optimate
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            San Francisco, CA
          </h4>
          <p>
            played a pivotal role in streamlining the development process and
            improving time-to-market by collaborating with cross-functional
            teams. Proactively identified and resolved software issues,
            contributing to heightened user satisfaction.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="May 2019 - Oct 2020"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Noc Engineer - Huawei
          </h3>
          <h4 className="vertical-timeline-element-subtitle">Nairobi, Kenya</h4>
          <p>
            Conducted site reliability engineering, reducing downtime by 90%,
            promptly responding to and resolving network incidents to minimize
            operational impact. I provided real-time monitoring, ticketing and
            troubleshooting. Ensuring high availability and efficient support for
            network engineers.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element"
          date="July 2022 - Feb 2023"
          iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Moringa School, Ngong Lane Plaza, Nairobi Kenya
          </h3>
          <p>Fullstack Software Engineering</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element"
          date="2014-2018"
          iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            South Eastern Kenya University
          </h3>
          <h4>Bachelor's Degree</h4>
          <p>Computer Science</p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
}

export default Experience;