import proj1 from "../assets/proj1.jpg";
import dev from "../assets/dev.jpg";
import proj2 from "../assets/nova1.png";
import proj4 from "../assets/auth.jpg";
import proj5 from "../assets/admin.png";
import proj6 from "../assets/weather.jpg";

export const ProjectList = [
  {
    id: 1,
    name: "Food Delivery App",
    image:
      "https://github.com/JamesKibathi/Food_chapchap/raw/main/Food-chapchap.png",
    description:
      "Food Chapchap is a highly responsive User Interface (UI) built using React and styled with Tailwind CSS. The UI simulates an online food ordering system, offering users the capability to filter food items based on categories and prices. Implemented the mobile-first responsive design approach using flexbox and grid layouts. JSON data was used to populate the UI with data and images, simulating the response from an API for a realistic user experience.",
    skills: "React, Tailwind CSS, Flexbox, JSON",
    link: "https://food-chapchap-two.vercel.app/",
    githubLink: "https://github.com/JamesKibathi/Food_chapchap",
  },
  {
    id: 2,
    name: "IT Company Website",
    image: proj2,
    description:
      "The Nova Savannah Company Website project involved the design and development of a comprehensive web platform for a Software Development and IT Consulting firm. The goal was to create an engaging online presence that not only showcases the company's services but also provides valuable resources for clients and tech enthusiasts.In summary, Nova Savannah's website embodies innovation, client engagement, and technical excellence, positioning the firm as a leader in the Software Development and IT Consulting landscape.",
    skills: "HTML, CSS,JavaScript, PHP, Wordpress CMS, Digital Ocean, NGINX",
    link: "https://novasavannah.com/",
    githubLink: "https://github.com/JamesKibathi",
  },

  {
    id: 3,
    name: "Customer Info API",
    image: proj5,
    description:
      "This is a backend API, tailored for the efficient management of customer information. The API captures a comprehensive set of data, ensuring a complete and organized record for each customer. Key features include:  Customer Profile -Captures essential personal details, including Customer Name, Contact Information (Phone and Email), and Date of Birth, Business Information:Records pertinent business details such as Business Name, Business Categories (e.g., Fintech, Learning Institution, Transportation, etc.), and Business Registration Date, Age of the Business:Dynamically calculates and updates the Age of the Business up to the current date, providing valuable insights into its longevity, Location Details:Thoroughly tracks the location of each customer, including County, Sub-county, Ward, Building Name, and Floor for precise geospatial information. All with full CRUD operations.",
    skills: "Django, Django Rest Framework(DRF), PostgreSQL, Rest API Design",
    link: "https://app.screencastify.com/v2/watch/xCSLYDU9NzVq0Hloe8VT",
    githubLink: "https://github.com/JamesKibathi/customer_info_api",
  },

  {
    id: 4,
    name: "Weather Forecast Application",
    image: proj6,
    description:
      "Utilized the Open Weather Map API and harnessed the power of JavaScript to develop a robust weather forecast application.This user-friendly platform empowers users to effortlessly access comprehensive weather condition information, including real-time data on temperature, wind speed, and humidity. By entering a specific city, users can obtain accurate and up-to-date weather details, enhancing their ability to plan and navigate their day effectively. The application not only delivers essential weather insights but also provides a seamless and engaging user experience, making it a valuable tool for staying informed about current atmospheric conditions.",
    skills: "JavaScript, Open Weather Map API, Flexbox",
    link: "https://jameskibathi.github.io/Mr-Weather-Man/",
    githubLink: "https://github.com/JamesKibathi/Mr-Weather-Man",
  },

  {
    id: 5,
    name: "PasswordLess Aunthentication Login",
    image: proj4,
    description:
      "Impelemented passwordless authentication using Django and React. Leveraged JWT and Twilio to send OTP codes via text messages for seamless login. Additionally, users receive a verification magic link URL via email upon signup, ensuring account authenticity.",
    skills:
      "Django, JSON Web Token(JWT),Twilio API, OTP Authentication, React,",
    link: "https://www.loom.com/share/1f0252dae6124cbd90bbb7e37597fae5",
    githubLink:
      "https://github.com/JamesKibathi/Password-less-Authentication-Backend",
  },

  {
    id: 6,
    name: "Developer Growth Platform",
    image: dev,
    description:
      "During my bootcamp at Moringa, we recognized the need for a platform where junior developers could document their progress as they learn software engineering. For beginners, existing sites like Medium can be intimidating. In a team of 4, we developed DevsPedia, a full-stack web application designed for junior developers to easily share and document their learning journey in software engineering.",
    skills:
      "React,Styled Components,RestFul API design, Ruby on Rails, PostgreSQL, Agile methodology",
    link: "https://devspedia-frontend.vercel.app/",
    githubLink: "https://github.com/JamesKibathi/Devspedia-Backend",
  },
];