import "./App.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Experience from "./pages/Experience";
import Footer from "./components/Footer";
import ProjectDisplay from "./pages/ProjectDisplay";
import AdminNav from "./pages/Admin/AdminNav";
import Item from "./components/Item";

function App() {
  const [showNav, setShowNav] = useState(true);

  return (
    <div className="App">
      <BrowserRouter>
        {showNav && (
          <nav>
            <NavBar />
          </nav>
        )}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route
            path="/projects/:projectId"
            element={
              <div className="page-container">
                <ProjectDisplay />
              </div>
            }
          />
          <Route path="/experience" element={<Experience />} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
