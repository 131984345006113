import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, Paper, Grow } from "@material-ui/core";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4),
    width: "80%", // Adjust the width as needed
    maxWidth: 600, // Set a max width if required
    backgroundColor: "#e9e9e9",
    borderRadius: 10,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
    cursor: "pointer",
  },
  containerHover: {
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
    },
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    color: "#3e497a", // Match your bio title color
  },
  button: {
    margin: theme.spacing(2),
    width: "100%", // Make buttons take full width
    backgroundColor: "#3e497a",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#21325e",
    },
  },
}));

const ContactsComponent = () => {
  const classes = useStyles();

  // State to handle animation
  const [animated, setAnimated] = React.useState(false);

  const handleAnimation = () => {
    setAnimated(true);
  };

  return (
    <Grow in={true} timeout={1000} onEntered={handleAnimation}>
      <Paper
        elevation={3}
        className={`${classes.container} ${classes.containerHover}`}
      >
        <Typography variant="h5" className={classes.title} gutterBottom>
          Ready to Elevate Your Next Project?
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          href="https://calendly.com/njenga-consulting/30min?month=2023-12"
          target="_blank"
          startIcon={<CallIcon />}
        >
          Book a Call
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          href="mailto:njenga.consulting@gmail.com"
          startIcon={<EmailIcon />}
        >
          Email me
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          href="https://www.linkedin.com/in/james-njenga/"
          target="_blank"
          startIcon={<LinkedInIcon />}
        >
          Let's connect on LinkedIn
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          href="https://twitter.com/its_jwiki"
          target="_blank"
          startIcon={<TwitterIcon />}
        >
          Follow me on Twitter
        </Button>
      </Paper>
    </Grow>
  );
};

export default ContactsComponent;
