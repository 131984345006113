// Updated Navbar.js
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/NavBar.css";
import ReorderIcon from "@material-ui/icons/Reorder";

function Navbar() {
  const [expandNavbar, setExpandNavbar] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setExpandNavbar(false);
  }, [location]);

  return (
    <div className={`navbar ${expandNavbar ? "open" : "close"}`}>
      <div className="toggleButton">
        <button onClick={() => setExpandNavbar((prev) => !prev)}>
          <ReorderIcon />
        </button>
      </div>
      <div className={`menu-container ${expandNavbar ? "show" : ""}`}>
        <div className={`links ${expandNavbar ? "" : "hide"}`}>
          <Link to="/">ABOUT</Link>
          <Link to="/projects">PROJECTS</Link>
          <Link to="/experience">EXPERIENCE</Link>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
