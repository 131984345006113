import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import CalendarIcon from "@mui/icons-material/EventNote";
import Contacts from "../components/Contacts";
import "../styles/Home.css";
import profilePhoto from "../assets/profile1.jpg";
import projectImage from "../assets/proj2.jpg";
import experienceImage from "../assets/proj1.jpg";

function Home() {
  const titleRef = useRef(null);

  useEffect(() => {
    // Add the animation class after a short delay (adjust as needed)
    setTimeout(() => {
      if (titleRef.current) {
        titleRef.current.classList.add("animate-in");
      }
    }, 500); // 500ms delay
  }, []);

  return (
    <div className="home">
      <div className="feature">
        <div className="feature-content">
          <h1 className="welcome-message">Hello, My Name is James</h1>
          <h2 ref={titleRef} className="title">
            {" "}
            Software Engineer{" "}
          </h2>
          <div className="photo-bio-wrapper">
            <img
              className="profile-photo"
              src={profilePhoto}
              alt="James Photo"
            />
            <div className="bio">
              <p>
                Accomplished Software Engineer with a wealth of experience
                gained from industry giants like Huawei, and a proven track
                record as a successful freelancer. I bring a meticulous and
                detail-oriented approach to software development, backed by
                strong problem-solving skills and a proven ability to thrive in
                collaborative team environments. With over 4 years of
                experience, I am passionate about creating impactful solutions
                that align with the ever-evolving landscape of information
                technology.
              </p>
            </div>
          </div>
          <div className="social-icons">
            <a
              href="https://twitter.com/its_jwiki"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon />
            </a>
            <a
              href="https://www.linkedin.com/in/james-njenga/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon />
            </a>
            <a
              href="https://github.com/JamesKibathi"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GitHubIcon />
            </a>
            <a
              href="https://calendly.com/njenga-consulting/30min"
              target="_blank"
              rel="noopener noreferrer"
            >
              <CalendarIcon />
            </a>
          </div>
        </div>
      </div>

      <div className="skills" id="skills">
        <h1> Technical Skills</h1>
        <ul className="list">
          <li className="item">
            {/* New section: Workflow */}
            <div className="item">
              <h2>Workflow</h2>
              <ul>
                <li>Agile Development & Scrum</li>
                <li>Cross Functional Teams</li>
                <li>Mobile-First, Responsive Design</li>
                <li>Full-Stack development & Debugging</li>
              </ul>
            </div>
          </li>
          <li className="item">
            <h2>Front-End</h2>
            <span>ReactJs, NextJs, Redux, Tailwind CSS, MaterialUI</span>
          </li>
          <li className="item">
            <h2>Back-End</h2>
            <span>
              Django, Rails, PostgreSQL,Celery, Redis, Docker, NGINX, REST APIs
            </span>
          </li>
          <li className="item">
            <h2>Languages</h2>
            <span>Python, JavaScript, Typescript, Ruby </span>
          </li>
        </ul>
      </div>

      {/* New section: Simplified Projects */}

      <Link to="/projects" className="project-card">
        <img src={projectImage} alt="Project" />
        <div className="project-info">
          <h2>Projects</h2>
          <p>Click to view all my projects</p>
        </div>
      </Link>

      {/* New section: Simplified Experience */}
      <Link to="/experience" className="experience-card">
        <img src={experienceImage} alt="Experience" />
        <div className="experience-info">
          <h2>Experience</h2>
          <p>Click to view all my experience</p>
        </div>
      </Link>
      <div className="contacts-container">
        <Contacts />
      </div>
    </div>
  );
}

export default Home;
